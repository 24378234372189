import verifyTest from "./Verify_test";

// API endpoint for setting test information.
const ENDPOINT = process.env.REACT_APP_API_URL + 'api/set-test-info/';

/**
 * Edits the information of a test with the specified parameters.
 * @param {string} test_id - The ID of the test to be edited.
 * @param {string} gen - The gen information.
 * @param {string} rsID - The rsID information.
 * @param {string} pharmGKB - The link to PharmGKB information.
 */
export default async function editTests(test_id, gen, rsID, pharmGKB) {
  try {
    // Retrieve the authentication token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a POST request to the API endpoint to edit the test information.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({
        id_chromosome: test_id,
        gene: gen,
        rsID: rsID,
        link_pharmagkb: pharmGKB
      }),
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    const data = await response.json();

    // If the test is successfully modified, trigger the verification process.
    if (data.message === "El test se ha modificado") {
      verifyTest(test_id);
    }

  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}
