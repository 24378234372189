import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Home } from './views/Home';
import { Contact } from './views/Contact';
import { AboutUs } from './views/About_us';
import { Departments } from './views/Departments';
import { Medicaments } from './views/Medicaments';
import { Login } from './views/Login';
import { UserContextProvider } from "./context/UserContext";
import { MultipleTests } from './views/Multiple_tests';
import { Signup } from './views/Signup';
import { Admin } from './views/Admin';
import { AdminUsers } from './views/Admin_users';
import { AdminTests } from './views/Admin_test';
import { EditTest } from './views/Edit_test';
import { Terms } from './views/Terms';


const App = () => {
  return (
    <CookiesProvider>
      <UserContextProvider>
        <div className="App">
          <BrowserRouter>
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/about_us' element={<AboutUs />} />
              <Route path='/departments' element={<Departments />} />
              <Route path='/medicaments' element={<Medicaments />} />
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/multiple_tests' element={<MultipleTests />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/admin/users' element={<AdminUsers />} />
              <Route path='/admin/tests' element={<AdminTests />} />
              <Route path='/admin/edit_test' element={<EditTest />} />
              <Route path='/terms' element={<Terms />} />
            </Routes>
          </BrowserRouter>
        </div>
      </UserContextProvider>
    </CookiesProvider>
  );
}

export default App;

