import Img from '../img/about_us.jpeg';
import pie from '../img/pie_pag.jpg';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AboutUs = () => {

    const { t } = useTranslation("About_us");
    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
            </div>
            <div className='App-backg2 '>
                <p className='Layout'>{t('description')}</p>
            </div>
            <img src={pie} className='App-pie' alt='footer' />
        </header>
    );
}
