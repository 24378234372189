import React from 'react';
import styled from 'styled-components';

/**
 * Modal component for displaying content in a pop-up window.
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - The content to be displayed inside the modal.
 * @param {boolean} props.estado - A boolean indicating whether the modal is open or closed.
 * @param {function} props.cambiarEstado - Function to change the state of the modal (open or close).
 * @param {string} [props.titulo='Alerta'] - The title of the modal.
 * @param {boolean} [props.mostrarHeader] - Whether to display the header with the title.
 * @param {boolean} [props.mostrarOverlay] - Whether to display the overlay background.
 * @param {string} [props.posicionModal] - The positioning of the modal content.
 * @param {string} [props.padding] - The padding of the modal content.
 * @returns {ReactNode} - The Modal component.
 */

const Modal = ({
	children,
	estado,
	mostrarOverlay,
	posicionModal,
	padding
}) => {
	return (
		<>
			{estado &&
				<Overlay mostrarOverlay={mostrarOverlay} posicionModal={posicionModal}>
					<ContenedorModal padding={padding}>
						{children}
					</ContenedorModal>
				</Overlay>

			}
		</>
	);
}

export default Modal;


const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: ${props => props.mostrarOverlay ? 'rgba(0,0,0,.5)' : 'rgba(0,0,0,0)'};
	padding: 40px;
	display: flex;
	align-items: ${props => props.posicionModal ? props.posicionModal : 'center'};
	justify-content: center;
`;

const ContenedorModal = styled.div`
	width: 50%;
	min-height: 100px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	box-shadow: rgba(100,100,111, 0.2) 0px 7px 29px 0px;
	padding: ${props => props.padding ? props.padding : '20px'};
`;