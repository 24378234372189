import { navigate } from "wouter/use-location";
import deleteTest from "./Delete_test";
import { isEmptyArray } from "formik";

// API endpoint for fetching unverified tests.
const ENDPOINT = process.env.REACT_APP_API_URL + 'api/get-unverified-test/';

// Variable to track the current page for pagination.
let currentPage = 1;

// Number of items to display per page.
const itemsPerPage = 5;

// Reference to the HTML container where the tests table will be displayed.
var container;

// Reference to the HTML container where the pagination information will be displayed.
var pagContainer;

// Variable to store the fetched data.
var data;

// Variable to store the total number of pages for pagination.
var totalPages;

/**
 * Fetches unverified tests from the server and displays them in a table with pagination.
 * @param {HTMLElement} tableBody - The HTML element where the tests table will be displayed.
 * @param {HTMLElement} pagBody - The HTML element where the pagination information will be displayed.
 */
export default async function getUnverifyTests(tableBody, pagBody) {
  try {
    // Set container references to the provided table body and pagination body.
    container = tableBody;
    pagContainer = pagBody;

    // Retrieve the authentication token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a GET request to the API endpoint to fetch unverified tests.
    const response = await fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      }
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    data = await response.json();

    // Display the paginated data.
    displayDataWithPagination();

  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}

/**
 * Displays unverified tests in a table with pagination.
 */
const displayDataWithPagination = () => {
  // Calculate the total number of pages based on the number of items per page.
  totalPages = Math.ceil(data.length / itemsPerPage);

  if (container != null) {
    if (pagContainer != null) {
      // Display pagination information.
      pagContainer.innerHTML = (totalPages === 0) ?
        `<small>Página ${currentPage} de 1 </small>` :
        `<small>Página ${currentPage} de ${totalPages}</small>`;
    }

    // Clear the existing content of the tests table.
    container.innerHTML = "";

    // Calculate the start and end index for the current page.
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the data array to get the items for the current page.
    const paginatedData = data.slice(startIndex, endIndex);

    if (isEmptyArray(data)) {
      document.getElementById("ListTests").className = "Inv";
      window.alert("Por el momento no hay tests por verificar.")
    }

    // Loop through the paginated data to display the unverified tests.
    paginatedData.forEach(test => {
      container.className ="checkBox"
      container.innerHTML += `
        <tr>
          <td>${test}</td>
          <td>
            <p type="button" id="Test-${test}">✏️</p>
          </td>
          <td>
            <input className="form-check-input" type="checkbox" name="flexRadioDefault-${test}" id="DeleteTests-${test}" />
          </td>
        </tr>
      `;
    });

    return container;
  }
}

// Array to store selected test for deleting tests.
var selectTest = [];

// Event listener for checkbox clicks to select/deselect tests.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("DeleteTests-")) {
    const test = id.split("-");
    if (selectTest.includes(test[1])) {
      selectTest.splice(selectTest.indexOf(test[1]), 1);
    } else {
      selectTest.push(test[1]);
      document.getElementById("btnAdminTest").className = "Vis";
    }
    if (selectTest.length === 0) {
      document.getElementById("btnAdminTest").className = "Inv";
    }
  }
}, false);

// Event listener for button clicks to perform actions on selected tests.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id === "btnDeleteTests") {
    if (selectTest.length > 0) {

      // Delete selected tests.
      selectTest.forEach(test_id => {
        deleteTest(test_id);
      });
      window.alert("Los tests han sido eliminados con éxito.");
      window.location.reload(true);
    }
  }
}, false);

// Event listener for editing a test.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("Test-")) {

    // Set the test data in sessionStorage for the edit_test route.
    window.sessionStorage.setItem('test', id);

    // Navigate to the edit_test route.
    navigate("/admin/edit_test");
    window.location.reload(true);
  }
}, false);

// Pagination functions
function goToPreviousPage() {
  if (currentPage > 1) {
    currentPage--;
    updateTable();
  }
}

function goToNextPage() {
  if (currentPage < totalPages) {
    currentPage++;
    updateTable();
  }
}

function updateTable() {
  // Clear the table before adding new data.
  container.innerHTML = '';
  // Display the updated paginated data.
  displayDataWithPagination();
}

// Event listener for the "Previous" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("previousButton")) {
    goToPreviousPage();
  }
}, false);

// Event listener for the "Next" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("nextButton")) {
    goToNextPage();
  }
}, false);
