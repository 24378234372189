/**
 * Fetch genotype frequencies related to a specific allele and variant (rsID) and display them in a table.
 * @param {HTMLElement} tableBody - The HTML element where the genotype frequencies will be displayed.
 */
export async function getFrecuency(tableBody) {
  const ale = window.ale; // Get the allele value from the window object.
  const url = process.env.REACT_APP_API_URL + 'api/frequency-by-genotype?genotype=' + ale + "&&rsID=" + window.va; // Define the API endpoint for fetching genotype frequencies.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API endpoint to fetch genotype frequencies.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Parse the response data as JSON.
      const data = await response.json();
      console.log(data);
      // Display the genotype frequencies in the specified table body.
      displayData(data, tableBody);
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

/**
 * Display genotype frequencies in the provided table body element.
 * @param {Array} data - An array of genotype frequencies data to be displayed.
 * @param {HTMLElement} tableBody - The HTML element where the genotype frequencies data will be displayed.
 */
const displayData = (data, tableBody) => {
  if (tableBody != null) {
    tableBody.innerHTML = "";
    tableBody.className = "Top";
    data.forEach(fre => {
      if (fre[0]) {
        // Display the frequency information in the specified format.
        tableBody.innerHTML += `
          <div>${window.frecuency_text}${fre[2]}${window.value_text}${fre[1]}</div>
        `;
      }
    });
    return tableBody;
  }
}
