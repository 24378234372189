import pie from '../img/pie_pag.jpg';
import Graph from '../components/Graph.jsx';
import React, { useEffect, useRef, useState } from 'react';
import { getGen } from '../services/Dep_table.js';
import Modal from '../components/Popup.js';
import Img from '../img/department.jpeg';
import { downloadData } from '../services/Download_data.js';
import useTour from '../components/Tour.tsx';
import getExistingRsID from '../services/Existig_rsid.js';
import { getGraphByrsID } from '../services/Graph.js';
import { useTranslation } from 'react-i18next';

export const Departments = () => {
    const { t } = useTranslation("Departments");

    // Tour steps for guiding the user through the interface.
    const STEPS = [
        {
            content: t('tour.0.content'),
            placement: "right",
            styles: {
                options: {
                    width: 300,
                },
            },
            target: ".graph",
            title: t('tour.0.title'),
        },
        {
            content: t('tour.1.content'),
            placement: "top",
            target: ".data",
            title: t('tour.1.title'),
        },
        {
            content: t('tour.2.content'),
            placement: "right",
            target: ".gene",
            title: t('tour.2.title'),
        },
        {
            content: t('tour.3.content'),
            placement: "right",
            target: ".snp",
            title: t('tour.3.title'),
        },
        {
            content: t('tour.4.content'),
            placement: "right",
            target: ".gkb",
            title: t('tour.4.title'),
        },
        {
            content: t('tour.5.content'),
            placement: "left",
            target: ".genotype",
            title: t('tour.5.title'),
        },
        {
            content: t('tour.6.content'),
            placement: "left",
            target: ".fre",
            title: t('tour.6.title'),
        }
    ];

    // Refs for graph and containers
    const graphContainerRef = useRef(null);
    const depContainerRef = useRef(null);
    const pagContainerRef = useRef(null);
    const rsContainerRef = useRef(null);
    const ancestryContainerRef = useRef(null);

    // State for controlling various modals
    const [modalGenotype, showModalGenotype] = useState(false);
    const [modalGen, showModalGen] = useState(false);
    const [modalLink, showModalLink] = useState(false);
    const [modalFre, showModalFre] = useState(false);
    const [modalSNP, showModalSNP] = useState(false);
    const [modalEth, showModalEth] = useState(false);

    // Effect to render the graph on component mount
    useEffect(() => {
        Graph(graphContainerRef.current);
        const name = window.name
        getExistingRsID(rsContainerRef.current, name, t('select_ancestry'));
        var container = document.getElementById('container');
        container.textContent = name;
    }, []);

    const handleChange = (event) => {
        const rsid = event.target.value;

        getGraphByrsID(ancestryContainerRef.current, window.name, rsid)

    };

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    const tour = useTour(STEPS, "DEP_KEY");

    // Function to fetch data and display it in the table
    function getData() {
        window.page = t('page')
        window.of_string = t('of')
        getGen(depContainerRef.current, pagContainerRef.current, t('no_data_available'));
        document.getElementById("pagBtn").className = "Vis Top";
        document.getElementById("dataBtn").className = "Inv";

    }

    return (
        <header className="App-header">
            {tour}
            <div className='App-backg1' >
                <div className="Back" style={backgroundStyle}>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
                <div className='App-backg2'>
                    <div className='Layout'>
                        {t('department')}
                        <h1><div className="Top" id="container" > </div></h1>
                    </div>
                </div>
                <div className='App-backg3'>
                    <div className='Layout'>
                        <table className="table table-borderless Right2">
                            <tbody>
                                <tr  >
                                    <td>
                                        <div ref={graphContainerRef} className='contorno Layout graph'></div>
                                    </td>
                                    <td>
                                        <div className="desplazado">
                                            <table className="table table-borderless" id='TDepart'>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalGen(!modalGen)}><h4 className='gene'>{t('gene')}</h4></button>
                                                            <Modal
                                                                estado={modalGen}
                                                                cambiarEstado={showModalGen}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('gene')}</h1>
                                                                    <p>{t('modals.gene')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalGen(!modalGen)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalSNP(!modalSNP)} ><h4 className='snp'>{t('pharmasnp')}</h4></button>
                                                            <Modal
                                                                estado={modalSNP}
                                                                cambiarEstado={showModalSNP}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('pharmasnp')}</h1>
                                                                    <p>{t('modals.pharmasnp')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalSNP(!modalSNP)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalLink(!modalLink)}><h4 className='gkb'>{t('pharmgkb')}</h4></button>
                                                            <Modal
                                                                estado={modalLink}
                                                                cambiarEstado={showModalLink}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('pharmgkb')}</h1>
                                                                    <p>{t('modals.pharmgkb')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalLink(!modalLink)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalGenotype(!modalGenotype)}><h4 className='genotype'>{t('genotype')}</h4></button>
                                                            <Modal
                                                                estado={modalGenotype}
                                                                cambiarEstado={showModalGenotype}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('genotype')}</h1>
                                                                    <p>{t('modals.genotype')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalGenotype(!modalGenotype)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalFre(!modalFre)}><h4 className='fre'>{t('frequency')}</h4></button>
                                                            <Modal
                                                                estado={modalFre}
                                                                cambiarEstado={showModalFre}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('frequency')}</h1>
                                                                    <p>{t('modals.frequency')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalFre(!modalFre)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                        <th scope="col">
                                                            <button className='btn' type='button' onClick={() => showModalEth(!modalEth)}><h4>{t('ethnicity')}</h4></button>
                                                            <Modal
                                                                estado={modalEth}
                                                                cambiarEstado={showModalEth}
                                                                mostrarHeader={true}
                                                                mostrarOverlay={true}
                                                                posicionModal={'center'}
                                                                padding={'20px'}
                                                            >
                                                                <>
                                                                    <h1>{t('ethnicity')}</h1>
                                                                    <p>{t('modals.ethnicity')}</p>
                                                                    <button className='btn btn-outline-dark' onClick={() => showModalEth(!modalEth)}>{t('buttons.close')}</button>
                                                                </>
                                                            </Modal>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="Tdepartamentos" ref={depContainerRef} >
                                                </tbody>
                                            </table>
                                            <div id="dataBtn" className='Vis'>
                                                <button className="btn btn-outline-dark Top data" id="fetchData" onClick={() => getData()}>{t('buttons.fetch_data')}</button>
                                            </div>
                                            <div id="pagBtn" className='Inv'>
                                                <button className="btn btn-outline-dark" id="previousButton">{t('buttons.previous_page')}</button>
                                                <small className='Top Right Left' ref={pagContainerRef} ></small>
                                                <button className="btn btn-outline-dark" id="nextButton">{t('buttons.next_page')}</button>
                                                <button className="btn btn-outline-dark Left" onClick={() => downloadData()}>{t('buttons.download_data')}</button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='App-backg4'>
                    <div className='Top Left Right Down'>
                        <p className=' Left Right Down'>
                            {t('ancestry')}
                        </p>
                        <div className='Menu Center'>
                            <select className="form-select" aria-label="Default select example" onChange={handleChange} ref={rsContainerRef}>
                            </select>
                        </div>
                        <div ref={ancestryContainerRef}></div>
                    </div>
                </div>
                <img src={pie} className='App-pie' alt='footer' />
            </div>
        </header>

    )
}
