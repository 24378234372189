import React from "react";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

// Styles for the Joyride component
const joyrideStyles = {
    options: {
        zIndex: 10000000,
    },
};

/**
 * Custom React hook for managing Joyride tours.
 * @param {Step[]} steps - Array of Joyride steps.
 * @param {string} localStorageKey - Key for storing tour state in localStorage.
 * @returns {ReactNode} - The Joyride component.
 */
export default function useTour(steps: Step[], localStorageKey: string | null): ReactNode {
    
    // State to manage whether the tour should run
    const [run, setRun] = useState(false);

    // Effect to check if the tour has been viewed before
    useEffect(
        function () {

            // If localStorageKey is not provided, always set run to true
            if (!localStorageKey) {
                setRun(true);
                return;
            }

            // Check if the tour has been viewed before
            const tourViewed = window.localStorage.getItem(localStorageKey);
            if (tourViewed) {
                return;
            }

            // If not viewed before, mark it as viewed and set run to true
            window.localStorage.setItem(localStorageKey, "1");
            setRun(true);
        },
        [localStorageKey]
    );

    // Callback function for Joyride events
    const handleJoyrideCallback = useCallback(function (data: CallBackProps) {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            // alert("Tour finalizado!");
        }
    }, []);

    // Memoized Joyride component
    const tour = useMemo<ReactNode>(
        () => (
            <Joyride
                callback={handleJoyrideCallback}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                showProgress={true}
                showSkipButton={true}
                steps={steps}
                styles={joyrideStyles}
            />
        ),
        [steps, handleJoyrideCallback, run]
    );

    return tour;
}