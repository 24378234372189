import { getEffects } from "./Effect";
import { getFrecuency } from "./Frecuency";

/**
 * Fetch genotype data for a specific variant and display it in a table.
 * @param {HTMLElement} tableBody - The HTML element where the genotype data will be displayed.
 */
export async function getGenotype(tableBody) {
  const va = window.va; // Get the variant value from the window object.
  const url = process.env.REACT_APP_API_URL + 'api/genotypes-by-variant?variant=' + va; // Define the API endpoint for fetching genotype data.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API endpoint to fetch genotype data.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Parse the response data as JSON.
      const data = await response.json();
      console.log(data);
      // Display the genotype data in the specified table body.
      displayData(data, tableBody);
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

/**
 * Display genotype data in the provided table body element.
 * @param {Array} data - An array of genotype data to be displayed.
 * @param {HTMLElement} tableBody - The HTML element where the genotype data will be displayed.
 */
const displayData = (data, tableBody) => {
  if (tableBody != null) {
    tableBody.innerHTML = "";
    tableBody.className = "Genotype";
    data.forEach(al => {
      tableBody.innerHTML += `
        <td id="Ale-${al}" type="button" onClick= {}>${al}</td>
      `;
    });
    return tableBody;
  }
}

// Add a click event listener to handle clicks on genotype data.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("Ale-")) {
    document.getElementById("eff").className = "Vis";
    document.getElementById("fre").className = "Vis";
    const selGenotype = window.sessionStorage.getItem("selGenotype")
    if (selGenotype !== null && document.getElementById(selGenotype) !== null) {
      document.getElementById(selGenotype).className = "";
    }
    document.getElementById(id).className = "GenotypeSelected";
    window.sessionStorage.setItem("selGenotype", id)

    // Extract the selected genotype value from the ID.
    const genotypes = id.split("-");
    window.ale = genotypes[1];
    // Call functions to get effects and frequency for the selected genotype.
    getEffects(window.effCon);
    getFrecuency(window.freCon);
  }
}, false);
