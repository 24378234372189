import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "bootstrap";
import useUser from '../hooks/useUser';
import logo from '../img/MapLogo.png';
import { useTranslation } from 'react-i18next';

// Navbar component
const Navbar = () => {
    const { t, i18n } = useTranslation('NavBar');

    // Retrieve admin status from session storage.
    const admin = window.sessionStorage.getItem("admin");

    // Use the useUser custom hook to manage user authentication.
    const { isLogged, logout } = useUser()

    // Handler for logout button click
    const handleClick = e => {

        // Clear the window.name
        window.name = "";
        e.preventDefault();

        // Call the logout function
        logout();
    }

    // Function to render buttons for the admin user
    const renderAdminButtons = () => {
        return <>
            <Link className="btn btn-light me-2" type="button" to='/' onClick={handleClick}>
                {t('logout')}
            </Link>
            <Link className="btn btn-outline-light me-2" type="button" to="/admin">Administrador</Link>
        </>
    }

    // Function to render login/signup buttons based on user authentication status
    const renderLoginButtons = ({ isLogged }) => {
        return isLogged
            ? <>
                <Link className="btn btn-light me-2" type="button" to='/' onClick={handleClick}>
                    {t('logout')}
                </Link>
                <Link className="btn btn-outline-light me-2" type="button" to="/multiple_tests">{t('upload_data')}</Link>
            </>
            : <>
                <Link className="btn btn-light me-2" type="button" to="/login">{t('login')}</Link>
                <Link className="btn btn-outline-light me-2" type="button" to="/signup">{t('signup')}</Link>

            </>
    }

    // Determine the content based on the admin status
    var content
    if (admin) {
        content = renderAdminButtons()
    } else {
        content = renderLoginButtons({ isLogged })
    }

    return (
        <div className="Navbar" >
            <nav className="navbar navbar-expand-lg navbar-dark">
                <div className="container-fluid">
                    <a className="navbar-brand">
                        <img src={logo} alt="Bootstrap" width="60" height="60" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item">
                                <Link className="nav-link active" aria-current="page" to="/">{t('home')}</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item dropdown">
                                <a className="nav-link active dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('search')}
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li><Link className="dropdown-item" to="/departments" >{t('departments')}</Link></li>
                                    <li><Link className="dropdown-item" to="/medicaments">{t('medicaments')}</Link></li>
                                </ul>
                            </li>

                        </ul>
                        <ul className="navbar-nav mx-auto">
                            <li className="navbar-nav mx-auto">
                                <Link className="nav-link active" to="/about_us">{t('about_us')}</Link>
                            </li>

                        </ul>
                        <ul className="navbar-nav mx-auto">
                            <li className="nav-item mx-auto">
                                <Link className="nav-link active" to="/contact">{t('contact')}</Link>
                            </li>
                        </ul>
                        <ul className="navbar-nav mx-auto">
                            <li>
                                <form className="container-fluid justify-content-start">
                                    {content}
                                </form>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </div>

    )
}

export default Navbar