import useUser from '../hooks/useUser';
import { useLocation } from "wouter"
import Img from '../img/signup.jpeg';
import pie from '../img/pie_pag.jpg';
import { useEffect, useRef, useState } from "react";
import useTour from '../components/Tour.tsx';
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from 'react-i18next';

// Initial values for form fields
const initialValues = {
    email: "",
    password: "",
    password2: "",
    name: "",
    lastName: "",
    job: "",
    company: "",
}

// Component for the Signup page
export function Signup() {
    const { t } = useTranslation("Signup");

    // Function to validate form fields
const validateFields = (values) => {
    const errors = {};

    // Validation for each field
    if (!values.email) {
        errors.email = t('email_required');
    } else if (values.email.length > 40) {
        errors.email = t('email_length');
    }
    if (!values.name) {
        errors.name = t('name_required');
    } else if (values.name.length > 20) {
        errors.name = t('name_length');
    }
    if (!values.lastName) {
        errors.lastName = t('lastName_required');
    } else if (values.lastName.length > 30) {
        errors.lastName = t('lastName_length');
    }
    if (!values.company) {
        errors.company = t('company_required');
    } else if (values.company.length > 40) {
        errors.company = t('company_length');
    }
    if (!values.job) {
        errors.job = t('job_required');
    } else if (values.job.length > 20) {
        errors.job = t('job_length');
    }
    if (!values.password) {
        errors.password = t('password_required');
    } else if (values.password.length < 8 || values.password.length > 16) {
        errors.password = t('password_length');
    }
    if (!values.password2) {
        errors.password2 = t('password_required');
    } else if (values.password2.length < 8 || values.password2.length > 16) {
        errors.password2 = t('password_length');
    } else if (values.password !== values.password2) {
        errors.password = t('passwords_mismatch');
    }

    return errors;
}

    // Tour steps for guiding the user through the interface.
    const STEPS = [
        {
            content: t('tour_step'),
            placement: "bottom",
            styles: {
                options: {
                    width: 300,
                },
            },
            target: ".title",
            title: t('title'),
        }
    ];

    const [, navigate] = useLocation();

    // Custom hook to get user authentication status
    const { isSignup, hasLoginError, signup, isLogged, admin, isLoginLoading } = useUser();

    // Effect to redirect if the user is already logged in
    useEffect(() => {
        if (isLogged) {

            //Redirect to admin page if the user is an admin
            if (admin) {
                navigate('/admin');
                window.location.reload(true);
            }

            //Redirect to multiple_tests page if the user is not an admin
            else {
                navigate('/multiple_tests');
                window.location.reload(true);
            }
        }
    }, [isLogged, navigate, admin])

    const tour = useTour(STEPS, "SIGNUP_KEY");

    const [captchaValido, cambiarCaptchaValidd] = useState(null)
    const captcha = useRef(null);
    function onChange(value) {
        if (captcha.current.getValue()) {
            console.log("Captcha value:", value)
            cambiarCaptchaValidd(true)
        }
    }

    function onSubmit(values) {
        if (captcha.current.getValue()) {
            signup(values)
            cambiarCaptchaValidd(true)
        } else {
            cambiarCaptchaValidd(false)
        }
    }

    // Style for the background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <>
            {isSignup &&
                <header className="App-header">
                    <div className='App-backg1'>
                        <div className="Back" style={backgroundStyle}>
                            <p />
                            <h1 className='title'>{t('title')}</h1>
                            <p>{t('subtitle')}</p>
                        </div>
                    </div>
                    <div className='App-backg2'>
                        {t('signup_success')}
                    </div>
                    <img src={pie} className='App-pie' alt='footer' />
                </header>
            }
            {!isSignup &&
                <header className="App-header">
                    {tour}
                    <div className='App-backg1'>
                        <div className="Back" style={backgroundStyle}>
                            <h1>{t('title')}</h1>
                            <p>{t('subtitle')}</p>
                        </div>
                    </div>
                    <div className='App-backg5'>
                        <Formik
                            initialValues={initialValues}
                            validate={validateFields}
                            onSubmit={(values, { setFieldError }) => {
                                return onSubmit(values)
                                    .then(() => {

                                    })
                                    .catch(() => {
                                        setFieldError("email", t('invalid_email'));
                                    });
                            }}
                        >
                            {({ errors }) => (
                                <Form>
                                    <div className="form">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-6">
                                                        <Field type="name"
                                                            className={errors.name ? 'error' : 'form-control'}
                                                            name="name"
                                                            placeholder={t('name')}
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='name' component='small' />
                                                    </td>
                                                    <td className="col-md-6">
                                                        <Field type="lastName"
                                                            className={errors.lastName ? 'error' : 'form-control'}
                                                            name="lastName"
                                                            placeholder={t('lastName')}
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='lastName' component='small' />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="col-md-6">
                                                        <Field
                                                            className={errors.job ? 'error' : 'form-control'}
                                                            name="job"
                                                            placeholder={t('job')}
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='job' component='small' />
                                                    </td>
                                                    <td className="col-md-6">
                                                        <Field
                                                            className={errors.company ? 'error' : 'form-control'}
                                                            name="company"
                                                            placeholder={t('company')}
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='company' component='small' />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="col-12">
                                            <Field type="email"
                                                className={errors.email ? 'error' : 'form-control'}
                                                name="email"
                                                placeholder={t('email')}
                                                required
                                            />
                                            <ErrorMessage className='form-error' name='email' component='small' />
                                        </div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className="col-md-6">
                                                        <Field
                                                            className={errors.password ? 'error' : 'form-control'}
                                                            name="password"
                                                            placeholder={t('password')}
                                                            type='password'
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='password' component='small' />
                                                    </td>
                                                    <td className="col-md-6">
                                                        <Field
                                                            className={errors.password2 ? 'error' : 'form-control'}
                                                            name="password2"
                                                            placeholder={t('confirm_password')}
                                                            type='password'
                                                            required
                                                        />
                                                        <ErrorMessage className='form-error' name='password2' component='small' />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                                        <label className="form-check-label" htmlFor="invalidCheck2">
                                            <h4 className='TC'> <button className='btn TC' type="button" onClick={() => window.open("/terms")} >{t('accept_terms')}</button></h4>
                                        </label>
                                    </div>
                                    <div className='Captcha'>
                                        <ReCAPTCHA
                                            className='Captcha'
                                            ref={captcha}
                                            sitekey="6Ld71sIpAAAAAN2r8aJLFkdKTWYukXKtzQH9-4DW"
                                            onChange={onChange}
                                        />
                                        {captchaValido === false && <div className='error-captcha'>
                                            {t('captcha_error')}
                                        </div>}
                                    </div>
                                    <div className="form">
                                        <button className="btn" disabled={isLoginLoading}>
                                            {t('signup')}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <img src={pie} className='App-pie' alt='footer' />
                </header>
            }
            {
                hasLoginError && <strong>{t('invalid_credentials')}</strong>
            }
        </>
    );
}
