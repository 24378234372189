// Define the API endpoint for checking if a user is an admin.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/isUserAnAdmin/';

/**
 * Checks if a user is an admin by making a request to the server.
 * @param {Object} email - The email of the user to be checked.
 */
export default async function isAdmin({ email }) {
  try {
    // Retrieve the JWT token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a POST request to the API endpoint to check if the user is an admin.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({ username: email })
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON and return it.
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle and log any errors that occur during the isAdmin check.
    console.error(error);
    throw error;
  }
}
