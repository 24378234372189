import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";

const languages = ["en", "es"];

i18n
  .use(XHR)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ["navigator"] },
    fallbackLng: 'es',
    backend: {
      loadPath: '/translation/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false
    },
    whitelist: languages,
    ns: ['About_us', 'Contact', 'Terms', 'Login', 'NavBar', 'Departments', 'Medicaments', 'Home', 'Multiple_tests', 'Signup'],
    defaultNS: 'About_us',
    debug: true
  });

export default i18n;