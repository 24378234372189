/**
 * Asynchronous function to get existing RsIDs.
 * 
 * @param {HTMLElement} tableBody DOM element that will act as a container for displaying data.
 * @param {string} department Parameter representing the department, used in the POST request.
 */
export default async function getExistingRsID(tableBody, department, select) {
  try {
    // Constant defining the URL for the API endpoint.
    const ENDPOINT = process.env.REACT_APP_API_URL + 'api/get-existing-graphics-rsid?department='+ department + "&&language=" + window.navigator.language;

    const container = tableBody;

    // Performs a fetch request to the server using the defined configuration.
    const response = await fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json"
      },
      mode: 'cors'
    });


    // Parse the response data as JSON.
    const data = await response.json();
    if (data.error === "No se encontraron archivos" || data.detail === "CSRF Failed: Origin checking failed - https://prediccion-colombia.virtual.uniandes.edu.co does not match any trusted origins."
    ) {

    }
    else {
      // Calls the displayData function to show the data in the DOM.
      displayData(data, container, select);
    }


  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}

/**
 * Function to display data in the provided container.
 * 
 * @param {Array} data Data to be displayed, in this case a list of 'rsid'.
 * @param {HTMLElement} container DOM element where data will be displayed.
 * @param {String} select
 */
const displayData = (data, container, select) => {

  if (container != null) {

    container.innerHTML = `<option selected disabled >${select}</option>`;

    // Iterates over the data (rsid) and adds them to the container as options in a select element.
    data.forEach(rsid => {
      container.innerHTML += `
        <option value="${rsid}">${rsid}</option>
      `;
    });
    // Returns the container with the added data.
    return container;
  }
}
