import { isEmptyArray } from "formik";
import activateUser from "./Activate_user";
import deleteUser from "./Delete_user";

// API endpoint for fetching inactive users.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/inactiveUsers/';

// Variable to track the current page for pagination.
let currentPage = 1;

// Number of items to display per page.
const itemsPerPage = 5;

// Reference to the HTML container where the inactive users table will be displayed.
var container;

// Reference to the HTML container where the pagination information will be displayed.
var pagContainer;

// Variable to store the fetched data.
var data;

// Variable to store the total number of pages for pagination.
var totalPages;

/**
 * Fetches inactive users from the server and displays them in a table with pagination.
 * @param {HTMLElement} tableBody - The HTML element where the inactive users table will be displayed.
 * @param {HTMLElement} pagBody - The HTML element where the pagination information will be displayed.
 */
export default async function getInactiveUsers(tableBody, pagBody) {
  try {

    // Set container references to provided table body and pagination body.
    container = tableBody;
    pagContainer = pagBody;

    // Retrieve the JWT token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a GET request to the API endpoint to fetch the inactive users.
    const response = await fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      }
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    data = await response.json();

    // Display the users' data in the specified table body.
    displayDataWithPagination();

  } catch (error) {

    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}

/**
 * Displays inactive users in a table with pagination.
 */
const displayDataWithPagination = () => {

  // Calculate the total number of pages based on the number of items per page.
  totalPages = Math.ceil(data.length / itemsPerPage);

  if (container != null) {
    if (pagContainer != null) {

      // Display pagination information.
      pagContainer.innerHTML = (totalPages === 0) ?
        `<small>Página ${currentPage} de 1 </small>` :
        `<small>Página ${currentPage} de ${totalPages}</small>`;
    }

    // Clear the existing content of the inactive users table.
    container.innerHTML = "";

    // Calculate the start and end index for the current page.
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the data array to get the items for the current page.
    const paginatedData = data.slice(startIndex, endIndex);

    if (isEmptyArray(data)) {
      document.getElementById("ListUsers").className = "Inv";
      window.alert("Por el momento no hay usuarios que necesiten ser activados.")
    }

    // Loop through the paginated data to display inactive users.
    paginatedData.forEach(user => {
      container.className ="checkBox"
      container.innerHTML += `
        <tr>
          <td>${user[2]}</td>
          <td>${user[3]}</td>
          <td>${user[1]}</td>
          <td>${user[5]}</td>
          <td>${user[4]}</td>
          <td>
            <input className="form-check-input" type="checkbox" name="flexRadioDefault-${user[1]}" id="SelectUserIn-${user[1]}" />
          </td>
        </tr>
      `;
    });

    return container;
  }
}

// Array to store selected users for actions like activating users and deleting users.
var selectUsers = [];

// Event listener for checkbox clicks to select/deselect users.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("SelectUserIn-")) {
    const email = id.split("-");
    if (selectUsers.includes(email[1])) {
      selectUsers.splice(selectUsers.indexOf(email[1]), 1);
    } else {
      selectUsers.push(email[1]);
      document.getElementById("btnU").className = "Vis Top";
    }
    if (selectUsers.length === 0) {
      document.getElementById("btnU").className = "Inv";
    }
  }
}, false);

// Event listener for button clicks to perform actions on selected users.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id === "btnGrantInactiveUsers") {
    if (selectUsers.length > 0) {

      // Activate selected users.
      selectUsers.forEach(email => {
        activateUser({ email });
      });
      window.alert("Los usuarios han sido activados con éxito.");
      window.location.reload(true);
    }

  } else if (id === "btnDeleteInactiveUsers") {
    if (selectUsers.length > 0) {

      // Delete selected users.
      selectUsers.forEach(email => {
        deleteUser({ email });
      });
      window.alert("Los usuarios han sido eliminados con éxito.");
      window.location.reload(true);
    }
  }
}, false);

// Pagination functions
function goToPreviousPage() {
  if (currentPage > 1) {
    currentPage--;
    updateTable();
  }
}

function goToNextPage() {
  if (currentPage < totalPages) {
    currentPage++;
    updateTable();
  }
}

function updateTable() {

  // Clear the table before adding new data.
  container.innerHTML = '';

  // Display the updated paginated data.
  displayDataWithPagination();
}

// Event listener for the "Previous" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("previousButton")) {
    goToPreviousPage();
  }
}, false);

// Event listener for the "Next" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("nextButton")) {
    goToNextPage();
  }
}, false);
