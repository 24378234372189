import Img from '../img/terms.jpg';
import pie from '../img/pie_pag.jpg';
import React, { useEffect, useRef } from 'react';
import { getTerms } from '../services/Terms';
import { useTranslation } from 'react-i18next';

export const Terms = () => {

    const { t } = useTranslation('Terms');

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    // Reference for the terms container
    const termsContainerRef = useRef(null);

    // Fetch terms on component mount
    useEffect(() => {
        getTerms(termsContainerRef.current);
    }, []);

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
            </div>
            <div className='App-backg2'>
                <div className='Layout'>
                    <p ref={termsContainerRef}></p>
                </div>
            </div>
            <img src={pie} className='App-pie' alt='footer' />
        </header>
    );
}
