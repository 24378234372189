// Define the API endpoint for logging out.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/logout/';

/**
 * Log the user out by sending a request to the server.
 * @param {string} token - The authentication token of the user.
 */
export default async function logout({ token }) {
  try {
    // Send a POST request to the logout endpoint with the user's token.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      }
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON and extract the user's token.
    const data = await response.json();
    const jwt = await data.message;

    return jwt;

  } catch (error) {
    // Handle and log any errors that occur during the logout process.
    console.error(error);
    throw error;
  }
}
