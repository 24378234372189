/**
 * Fetches terms and conditions data from the specified API endpoint.
 * @param {HTMLElement} tableBody - The HTML element where the terms data will be displayed.
 */
export async function getTerms(tableBody) {
  const url =  process.env.REACT_APP_API_URL + 'api/terms-and-conditions?language=' + window.navigator.language;
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API endpoint to fetch terms data.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Parse the response data as JSON.
      const data = await response.json();
      console.log(data);

      // Display the data on the web page.
      displayData(data, tableBody);
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

/**
 * Displays terms and conditions data on the web page.
 * @param {Array} data - The terms data to be displayed.
 * @param {HTMLElement} tableBody - The HTML element where the terms data will be displayed.
 */
const displayData = (data, tableBody) => {

  if (tableBody != null) {
    tableBody.innerHTML = "";
    data.forEach((terms) => {
      tableBody.innerHTML += `
        <h1>${terms[0]}</h1>
      `;
      for (let j = 1; j < terms.length; j++) {
        tableBody.innerHTML += `
          <p>${terms[j]}</p>
        `;
      }
    });
    return tableBody;
  }
};