import useUser from '../hooks/useUser';
import { useLocation } from "wouter";
import Img from '../img/signup.jpeg';
import pie from '../img/pie_pag.jpg';
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import useTour from '../components/Tour.tsx';
import { useTranslation } from 'react-i18next';


export function Login({ onLogin }) {

    const { t } = useTranslation('Login');

    // State to manage email and password input fields
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [, navigate] = useLocation();

    const [cookies, setCookie] = useCookies(['csrftoken']);



    // Tour steps for guiding the user through the interface.
    const STEPS = [
        {
            content: t('tour.content'),
            placement: "bottom",
            styles: {
                options: {
                    width: 300,
                },
            },
            target: ".title",
            title: "PREDICCIÓN-COLOMBIA",
        }
    ];

    const tour = useTour(STEPS, "LOGIN_KEY");

    // Custom hook for user-related actions
    const { isLoginLoading, hasLoginError, login, isLogged, admin } = useUser();

    // useEffect to handle redirection after successful login
    useEffect(() => {
        function getCookie(name) {
            let matches = document.cookie.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }
        const cookie = getCookie('csrftoken');
        if (isLogged) {
            if (admin) {
                //Redirect to admin page if the user is an admin
                navigate('/admin')
                window.location.reload(true);
            }
            else {
                //Redirect to multiple_tests page if the user is not an admin
                navigate('/multiple_tests');
                window.location.reload(true);
                onLogin && onLogin();
            }
        }
        setCookie(cookie)
    }, [isLogged, navigate, onLogin, admin, setCookie])

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        login(email, password, cookies['csrftoken']);
    };

    // Style for the background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <>
            {isLoginLoading &&
                <header className="App-header">
                    <div className='App-backg1'>
                        <div className="Back" style={backgroundStyle}>
                            <p />
                            <h1 className='title'>{t('title')}</h1>
                            <p>{t('subtitle')}</p>
                        </div>
                    </div>
                    <div className='App-backg2'>{t('checking_credentials')}</div>
                    <p></p>
                    <img src={pie} className='App-pie' alt='footer' />
                </header>
            }
            {!isLoginLoading &&
                <header className="App-header">
                    {tour}
                    <div className='App-backg1'>
                        <div className="Back" style={backgroundStyle}>
                            <p />
                            <h1>{t('title')}</h1>
                            <p>{t('subtitle')}</p>
                        </div>
                    </div>
                    <div className='App-backg2'>
                        <form onSubmit={handleSubmit} className="form">
                            <div className='mb-3'>
                                <input type="email" className="form-control"
                                    placeholder={t('form.email_placeholder')}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </div>
                            <div className='mb-3'>
                                <input type="password" className="form-control"
                                    placeholder={t('form.password_placeholder')}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                />
                            </div>
                            <button className='btn' >{t('form.submit_button')}</button>
                        </form>
                    </div>
                    <p></p>
                    <img src={pie} className='App-pie' alt='footer' />

                </header>
            }
            {
                hasLoginError && <strong>{t('invalid_credentials')}</strong>
            }
        </>
    );
}