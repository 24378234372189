import React, { useState } from 'react'

// Create a React context with an initial value of an empty object
const Context = React.createContext({})

// Define a component that will serve as the provider for the context
export function UserContextProvider({ children }) {
    // Use the useState hook to manage the JWT state, initialized with the value from sessionStorage
    const [jwt, setJWT] = useState(
        () => window.sessionStorage.getItem('jwt')
    )

    // Provide the context value with the JWT state and setJWT function
    return <Context.Provider value={{
        jwt,
        setJWT
    }}>
        {children}
    </Context.Provider>
}

export default Context