import { Link } from 'react-router-dom';
import pie from '../img/pie_pag.jpg';
import React from 'react';
import Img from '../img/home.jpeg';
import useTour from '../components/Tour.tsx';
import logo from '../img/MapLogo.png'
import { useTranslation } from 'react-i18next';

export const Home = () => {
    const { t } = useTranslation("Home");

    // Tour steps for guiding the user through the interface.
    const STEPS = [
        {
            content: <h3>{t('tour.welcome')}</h3>,
            placement: "center",
            target: "body",
        },
        {
            content: t('tour.explore'),
            placement: "bottom",
            styles: {
                options: {
                    width: 300,
                },
            },
            target: ".home",
            title: t('title'),
        },
        {
            title: t('tour.goal_title'),
            placement: "top",
            target: ".goal",
        },
        {
            content: t('tour.dep_description'),
            placement: "top",
            target: ".dep",
            title: t('tour.visualization_title'),
        },
        {
            content: t('tour.med_description'),
            placement: "top",
            target: ".med",
            title: t('tour.visualization_title'),
        },
    ];

    // Style for the background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    const tour = useTour(STEPS, "HOME_KEY");

    return (
        <header className="App-header">
            {tour}
            <div className='home'>
                <div className='App-backg1' >
                    <div className="Back color" style={backgroundStyle}>
                        <p />
                        <h1>{t('title')}</h1>
                        <p>{t('subtitle')}</p>
                        <img src={logo} className='App-logo' alt='logo' />
                    </div>
                    <div className='Layout'>
                        <p>{t('description')}</p>
                    </div>
                    <div className='App-backg2'>
                        <table className="table table-borderless App-backg2">
                            <tbody className='Layout'>
                                <tr className='Rejilla'>
                                    <td className='goal'>
                                        <h1>{t('goal_title')}</h1>
                                        <p>{t('goal')}</p>
                                    </td>
                                    <td> <p></p></td>
                                    <td>
                                        <h2>{t('visualization_title')}</h2>
                                        <p></p>
                                        <div className='Top'>
                                            <Link className="btn btn-outline-light me-2 btn-lg dep" type="button" to="/departments">{t('departments')}</Link>
                                            <Link className="btn btn-outline-light me-2 btn-lg med" type="button" to="/medicaments">{t('medicaments')}</Link>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='App-backg3'>
                        <div className='Layout'>
                            <h2>
                                {t('disclaimers_title')}
                            </h2>
                            <div className='Layout'>
                                <p>{t('disclaimer1')}</p>
                                <p>{t('disclaimer2')}</p>
                                <p>{t('disclaimer3')}</p>
                                <p>{t('disclaimer4')}</p>
                            </div>
                        </div>
                    </div>
                    <img src={pie} className='App-pie' alt='footer' />
                </div>
            </div>
        </header>
    )
}
