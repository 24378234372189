import { navigate } from "wouter/use-location";

// API endpoint for deleting a test.
const ENDPOINT = process.env.REACT_APP_API_URL + 'api/delete-test/';

/**
 * Deletes a test with the specified test ID.
 * @param {string} test_id - The ID of the test to be deleted.
 */
export default async function deleteTest(test_id) {
  try {

    // Retrieve the authentication token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a POST request to the API endpoint to delete the test.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({
        id_chromosome: test_id
      }),
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    const data = await response.json();

    // Remove session storage items related to the test.
    window.sessionStorage.removeItem('gen');
    window.sessionStorage.removeItem('rsID');
    window.sessionStorage.removeItem('pharmGKB');

    // Navigate to the "/admin/tests" route.
    navigate("/admin/tests");

    // Reload the page to reflect changes.
    window.location.reload(true);
    console.log(data.message);

  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}
