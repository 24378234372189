import pie from '../img/pie_pag.jpg';
import React from 'react';
import Img from '../img/contact.jpg';
import { useTranslation } from 'react-i18next';

export const Contact = () => {
    const { t } = useTranslation("Contact");
    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
            </div>
            <p className='App-backg2' dangerouslySetInnerHTML={{ __html: t('more_info') }} />
            <img src={pie} className='App-pie' alt='footer' />
        </header>
    );
}
