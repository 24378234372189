import deleteUser from "./Delete_user";
import removeAdmin from "./Remove_admin";

// API endpoint for fetching admin users
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/adminUsers/';

// Variable to track the current page for pagination.
let currentPage = 1;

// Number of items to display per page.
const itemsPerPage = 5;

// Reference to the HTML container where the admin users table will be displayed.
var container;

// Reference to the HTML container where the pagination information will be displayed.
var pagContainer;

// Variable to store the fetched data.
var data;

// Variable to store the total number of pages for pagination.
var totalPages;

/**
 * Fetches admin users from the server and displays them in a table with pagination.
 * @param {HTMLElement} tableBody - The HTML element where the admin users table will be displayed.
 * @param {HTMLElement} pagBody - The HTML element where the pagination information will be displayed.
 */
export default async function getAdminUsers(tableBody, pagBody) {
  try {
    // Set container references to provided table body and pagination body.
    container = tableBody;
    pagContainer = pagBody;

    // Retrieve the authentication token from the session storage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a GET request to the API endpoint to fetch admin users.
    const response = await fetch(ENDPOINT, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      }
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    data = await response.json();

    // Display the paginated data.
    displayDataWithPagination();

  } catch (error) {

    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}

// Get the email of the logged-in admin user.
const adminEmail = window.sessionStorage.getItem("email");

/**
 * Displays admin users in a table with pagination.
 */
const displayDataWithPagination = () => {

  // Calculate the total number of pages based on the number of items per page.
  totalPages = Math.ceil(data.length / itemsPerPage);

  if (container != null) {
    if (pagContainer != null) {

      // Display pagination information.
      pagContainer.innerHTML = (totalPages === 0) ?
        `<small>Página ${currentPage} de 1 </small>` :
        `<small>Página ${currentPage} de ${totalPages}</small>`;
    }

    // Clear the existing content of the admin users table.
    container.innerHTML = "";

    // Calculate the start and end index for the current page.
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the data array to get the items for the current page.
    const paginatedData = data.slice(startIndex, endIndex);

    if (data.length === 1) {
      document.getElementById("ListUsers").className = "Inv";
      window.alert("Por el momento no hay otros administradores.")
    }

    // Loop through the paginated data to display admin users.
    paginatedData.forEach(user => {
      if (user[1] !== adminEmail) {
        container.className ="checkBox"
        container.innerHTML += `
          <tr>
            <td>${user[2]}</td>
            <td>${user[3]}</td>
            <td>${user[1]}</td>
            <td>${user[5]}</td>
            <td>${user[4]}</td>
            <td>
              <input className="form-check-input" type="checkbox" name="flexRadioDefault-${user[1]}" id="SelectUserAd-${user[1]}" />
            </td>
          </tr>
        `;
      }
    });

    return container;
  }
}

// Array to store selected users for actions like removing admin permissions and deleting users.
var selectUsers = [];

// Event listener for checkbox clicks to select/deselect users.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("SelectUserAd-")) {
    const email = id.split("-");
    if (selectUsers.includes(email[1])) {
      selectUsers.splice(selectUsers.indexOf(email[1]), 1);
    } else {
      selectUsers.push(email[1]);
      document.getElementById("btnU3").className = "Vis Top";
    }
    if (selectUsers.length === 0) {
      document.getElementById("btnU3").className = "Inv";
    }
  }
}, false);

// Event listener for button clicks to perform actions on selected users.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id === "btnRemoveAdminUsers") {
    if (selectUsers.length > 0) {

      // Remove admin permissions for selected users.
      selectUsers.forEach(email => {
        removeAdmin({ email });
      });
      window.alert("Los permisos de administrador de los usuarios han sido removidos.");
      window.location.reload(true);
    }

  } else if (id === "btnDeleteAdminUsers") {
    if (selectUsers.length > 0) {
      // Delete selected users.
      selectUsers.forEach(email => {
        deleteUser({ email });
      });
      window.alert("Los usuarios han sido eliminados con éxito.");
      window.location.reload(true);
    }
  }
}, false);

// Pagination functions
function goToPreviousPage() {
  if (currentPage > 1) {
    currentPage--;
    updateTable();
  }
}

function goToNextPage() {
  if (currentPage < totalPages) {
    currentPage++;
    updateTable();
  }
}

function updateTable() {

  // Clear the table before adding new data.
  container.innerHTML = '';

  // Display the updated paginated data.
  displayDataWithPagination();
}

// Event listener for the "Previous" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("previousButton")) {
    goToPreviousPage();
  }
}, false);

// Event listener for the "Next" button click event.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("nextButton")) {
    goToNextPage();
  }
}, false);
