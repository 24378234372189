// Define the API endpoint for user signup.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/signup/';

/**
 * Sign up a new user with the provided information.
 * @param {Object} data - An object containing user data.
 * @param {string} data.email - The user's email address.
 * @param {string} data.name - The user's first name.
 * @param {string} data.lastName - The user's last name.
 * @param {string} data.password - The user's password.
 */
export default async function signup({ email, name, lastName, password, company, job }) {
  try {
    // Send a POST request to the signup API with user data.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email: email,
        first_name: name,
        last_name: lastName,
        password: password,
        company: company,
        profession: job, 
        language: window.navigator.language
      }),
    });

    // Parse the response data as JSON and extract the message.
    const data = await response.json();
    const message = await data.message;

    // Check if the message is undefined and handle accordingly
    if (message === undefined) {
      window.alert(data.error);
      window.sessionStorage.setItem("signup", "error");
    }
    else {
      window.sessionStorage.setItem("signup", "valid");
    }

    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    return message;
  } catch (error) {
    // Handle and log any errors that occur during the signup process.
    console.error(error);
    throw error;
  }
}
