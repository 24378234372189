// Define the API endpoint for user login.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/login/';

/**
 * Log in a user by sending a request to the server.
 * @param {string} email - The user's email.
 * @param {string} password - The user's password.
 */
export default async function login( email, password, csrfToken ) {
  try {

    // Send a POST request to the login endpoint with the user's email and password.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrfToken
      },
      body: JSON.stringify({ email: email, password: password, language: window.navigator.language })
    });

    const data = await response.json();

    if (!response.ok) {
      window.alert(data.error);
    }

    // Parse the response data as JSON and extract the user's token.
    const jwt = await data.token;

    return jwt;
  } catch (error) {
    // Handle and log any errors that occur during the login process.
    console.error(error);
    throw error;
  }
}
