/**
 * Downloads department data in CSV format.
 */
export async function downloadData() {
  try {
    // Get the department name from the window object.
    const name = window.name;

    // Define the API endpoint for downloading department data in CSV format.
    const url = process.env.REACT_APP_API_URL + `api/departments-search-csv?department=${name}` + "&&language=" + window.navigator.language;

    // Define headers for the API request.
    const headers = {
      'Content-Type': 'application/json',
    };

    // Send a GET request to the API endpoint to fetch department data in CSV format.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Convert the response data to a blob.
      const dataBlob = await response.blob();

      // Create a blob link to download the CSV file.
      const downloadUrl = window.URL.createObjectURL(new Blob([dataBlob]));
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute('download', `${name}.csv`);

      // Append the link to the HTML page.
      document.body.appendChild(downloadLink);

      // Force the download.
      downloadLink.click();

      // Clean up and remove the link from the HTML page.
      document.body.removeChild(downloadLink);
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}
