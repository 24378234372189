import Img from '../img/files.jpg';
import pie from '../img/pie_pag.jpg';
import { useLocation } from "wouter";
import React, { useState } from 'react'
import useUser from '../hooks/useUser';
import { useEffect } from "react";
import upload_tests from '../services/Multiple_tests';
import Modal from '../components/Popup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useTranslation } from 'react-i18next';

// Initial values for form fields
const initialValues = {
    department: "",
}


export const MultipleTests = () => {
    const { t } = useTranslation("Multiple_tests");

    // Function to validate form fields
    const validateFields = values => {
        const errors = {};

        // Validation for each field

        if (!values.department) {
            errors.department = t('department_required');
        } else if (values.department.length > 20) {
            errors.department = t('department_length');
        }

        return errors;
    }

    // Custom hook to get user authentication status
    const { isLogged } = useUser();
    const [, navigate] = useLocation();

    // State to store the selected file
    const [file, setFile] = useState(null);

    // Effect to redirect if the user is not logged in
    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
            window.location.reload(true);
        }

    }, [isLogged, navigate])

    // Function to handle file selection
    const loadFile = e => {
        setFile(e);
    };

    // Funtion to redirect if the user select "No"
    const no = e => {
        showModalTest(!modalTest);
        window.open("/terms");
    }

    const handleChange = (event) => {
        const ethnicity = event.target.value;
        window.sessionStorage.setItem('ethnicity', ethnicity);
    };

    // Function to handle form submission
    const handleSubmit = (e) => {
        const name = file.name;
        console.log(file);

        // Check if the selected file is a VCF file
        if (name.includes(".vcf")) {
            // Call the function to upload multiple tests with the selected file
            upload_tests({ file });
        }
        else {
            window.alert(t('file_error'))
        }
    };

    // State for controlling the modal
    const [modalTest, showModalTest] = useState(false);

    // Style for the background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
            </div>
            <div className='App-backg5'>
                <label htmlFor="formFileLg" className="form-label Top"><h1>{t('upload_here')}</h1></label>
                <div className='Layout'>
                    <p>
                        {t('read_specifications')}
                        {t('only_vcf')}
                        {t('modification_note')}
                        {t('missing_columns')}
                        {t('column_structure')}
                        {t('max_file_size')}
                    </p>
                </div>
                <table className="table table-borderless App-backg5">
                    <tbody className='Layout Left minTop'>
                        <tr className='Rejilla2'>
                            <td className='Left'>
                                <div>
                                    <h4>{t('vcf_format')}</h4>
                                    <input className="form-control form-control-lg" id="formFileLg" type="file" onChange={e => loadFile(e.target.files[0])} />
                                </div>
                            </td>
                            <td> <p></p></td>
                            <td>
                                <h4 className='Left'>
                                    {t('additional_fields')}
                                </h4>
                                <div className='Left'>
                                    <Formik
                                        initialValues={initialValues}
                                        validate={validateFields}
                                        onSubmit={(values, { setFieldError }) => {
                                            window.sessionStorage.setItem('department', values.department);
                                            if (file !== null) {
                                                showModalTest(!modalTest);
                                            }
                                            else {
                                                window.alert(t('file_error'))
                                            }
                                        }}
                                    >{({ errors }) => (
                                        <Form>
                                            <div className="form forms">
                                                <table className='forms'>
                                                    <tbody>
                                                        <tr>
                                                            <td className="col-auto">
                                                                <Field
                                                                    className={errors.department ? 'error' : 'form-control form-control-lg dep'}
                                                                    name="department"
                                                                    placeholder={t('department_placeholder')}
                                                                    required
                                                                />
                                                                <ErrorMessage className='form-error' name='department' component='small' />
                                                            </td>
                                                            <td className="col-auto">
                                                                <select className="form-select ethnicity" aria-label="Default select example" onChange={handleChange}>
                                                                    <option selected disabled >{t('select_ethnicity')}</option>
                                                                    <option value="Afrodescendiente">{t('afrodescendant')}</option>
                                                                    <option value="Indígena" >{t('indigenous')}</option>
                                                                    <option value="Mestizo" >{t('mestizo')}</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className='Azul Top align form'>
                                                <button className="btn align forms Top Left" type='submit'>{t('upload_button')}</button>
                                            </div>
                                        </Form>
                                    )}
                                    </Formik>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Modal
                    estado={modalTest}
                    cambiarEstado={showModalTest}
                    mostrarHeader={true}
                    mostrarOverlay={true}
                    posicionModal={'center'}
                    padding={'20px'}
                >
                    <>
                        <div className='popUp'>
                            <h1>{t('important')}</h1>
                            <p>{t('consent_confirmation')}</p>
                            <button className='btn btn-outline-dark' onClick={() => handleSubmit()}>{t('yes')}</button>
                            <button className='btn btn-dark' onClick={() => no()}>{t('no')}</button>
                        </div>
                    </>
                </Modal>
            </div>
            <img src={pie} className='App-pie' alt='footer' />
        </header>
    )
}
