import { getVariant } from "./Variant";

/**
 * Fetch genes data related to a specific drug and display it in a table.
 * @param {HTMLElement} tableBody - The HTML element where the genes data will be displayed.
 */
export async function getGenes(tableBody) {
  var med = window.med
  const url = process.env.REACT_APP_API_URL + 'api/genes-by-drug?drug=' + med + "&&language=" +window.navigator.language; // Define the API endpoint for fetching genes data.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API endpoint to fetch genes data.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

      // Parse the response data as JSON.
      const data = await response.json();

    // Check if the response is successful.
    if (response.ok) {
      console.log(data);
      // Display the genes data in the specified table body.
      displayData(data, tableBody);
    } else {
      if (response.status == 404) {
        tableBody.innerHTML = '';
        window.alert(data)
      }
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

/**
 * Display genes data in the provided table body element.
 * @param {Array} data - An array of genes data to be displayed.
 * @param {HTMLElement} tableBody - The HTML element where the genes data will be displayed.
 */
const displayData = (data, tableBody) => {
  if (tableBody != null) {
    tableBody.className = "NotSelected";
    tableBody.innerHTML = "";
    data.forEach(gene => {
      if (gene[1]) {
        // Display the gene information in the specified format.
        tableBody.innerHTML += `
          <div><a href="${gene[1]}">${gene[0]}</a><div>
        `;
      }
      else {
        tableBody.innerHTML += `
         <div id="Gene-${gene[0]}" type="button" onClick= {}>${gene[0]}</div>
      `;
      }
    });
    return tableBody;
  }
}

// Add a click event listener to handle clicks on genes data.
document.addEventListener("click", function (event) {
  const id = event.target.id;
  if (id.includes("Gene-")) {
    document.getElementById("var").className = "Vis";
    const selGene = window.sessionStorage.getItem("selGene")
    if (selGene !== null && document.getElementById(selGene) !== null) {
      document.getElementById(selGene).className = "NotSelected";
    }
    document.getElementById(id).className = "Selected";
    window.sessionStorage.setItem("selGene", id)

    // Extract the selected gene value from the ID.
    const genes = id.split("-");
    window.gen = genes[1];
    // Call the function to get variants related to the selected gene.
    getVariant(window.varCon);
  }
}, false);
