import { getGenotype } from "./Genotype";

/**
 * Fetch variants by gene and display them in the provided table body.
 * @param {HTMLElement} tableBody - The table body element where variants will be displayed.
 */
export async function getVariant(tableBody) {
  // Get the selected gene from the window object.
  const gen = window.gen;
  var med = window.med
  // Construct the API URL for fetching variants by gene.
  const url = process.env.REACT_APP_API_URL + 'api/variants-by-gene?gene=' + gen + "&&drug=" + med + "&&language=" + window.navigator.language;

  // Define headers for the fetch request.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API to fetch variants.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    if (response.ok) {
      // If the response is successful, parse the data and display it.
      const data = await response.json();
      console.log(data);
      displayData(data, tableBody);
    } else {
      // If the response is not OK, log the error.
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    // Handle any exceptions that occur during the fetch.
    console.error('Error in the request:', error);
  }
}

/**
 * Display variant information in the provided table body.
 * @param {Array} data - An array of variant information.
 * @param {HTMLElement} tableBody - The table body element to display variant information.
 */
const displayData = (data, tableBody) => {
  if (tableBody != null) {
    tableBody.innerHTML = "";
    tableBody.className = "NotSelected";
    data.forEach(va => {
      // Create HTML elements to display variants as clickable items.
      tableBody.innerHTML += `
        <div id="Var-${va}" type="button" onClick= {}>${va}</div>
      `;
    });
    return tableBody;
  }
}

// Listen for click events on the document.
document.addEventListener("click", function (event) {
  const id = event.target.id;

  // Check if the clicked element's id contains "Var-".
  if (id.includes("Var-")) {
    // Hide the "eff" element and show the "ale" element.
    document.getElementById("eff").className = "Inv";
    document.getElementById("ale").className = "Vis";
    const selVar = window.sessionStorage.getItem("selVar")
    if (selVar !== null && document.getElementById(selVar) !== null) {
      document.getElementById(selVar).className = "NotSelected";
    }
    document.getElementById(id).className = "Selected";
    window.sessionStorage.setItem("selVar", id)

    // Extract the variant information and call getGenotype function.
    const variants = id.split("-");
    window.va = variants[1];
    getGenotype(window.aleCon);
  }
}, false);
