import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n'; // Importa tu configuración de i18next
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Frame Busting: Breaks out of the frame if the application is loaded within an iframe.
if (window !== window.top) {
    window.top.location = window.location;
}

// Create a root for rendering the React application.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application with Helmet for setting security headers.
root.render(
    <I18nextProvider i18n={i18n}>
        <App />
        </I18nextProvider>
);

// Measure and report web vitals for performance monitoring.
reportWebVitals();
