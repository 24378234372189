// Define the API endpoint for deleting a user account.
const ENDPOINT = process.env.REACT_APP_API_URL + 'auth/deleteUser/';

/**
 * Deletes a user account by making a request to the server.
 * @param {Object} email - The email of the user to be deleted.
 */
export default async function deleteUser({ email }) {
  try {
    // Retrieve the JWT token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a POST request to the API endpoint to delete the user account.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({ username: email })
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON and return it.
    const data = await response.json();
    return data;
  } catch (error) {
    // Handle and log any errors that occur during the account deletion process.
    console.error(error);
    throw error;
  }
}
