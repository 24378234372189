/**
 * Fetch effects related to a specific genotype, allele, and variant (rsID) and display them in a table.
 * @param {HTMLElement} tableBody - The HTML element where the effects will be displayed.
 */
export async function getEffects(tableBody) {
  const ale = window.ale; // Get the allele value from the window object.

  var med = window.med
  const url = process.env.REACT_APP_API_URL + 'api/effects-by-genotype?genotype=' + ale + "&&rsID=" + window.va + "&&drug_name=" + med + "&&language=" + window.navigator.language; // Define the API endpoint for fetching effects.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Send a GET request to the API endpoint to fetch effects.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Parse the response data as JSON.
      const data = await response.json();
      console.log(data);
      // Display the effects in the specified table body.
      displayData(data, tableBody);
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

/**
 * Display effects in the provided table body element.
 * @param {Array} data - An array of effects data to be displayed.
 * @param {HTMLElement} tableBody - The HTML element where the effects data will be displayed.
 */
const displayData = (data, tableBody) => {
  if (tableBody != null) {
    tableBody.innerHTML = "";
    data.forEach(eff => {
      // Display the effect information in the specified format.
      tableBody.innerHTML += `
        <div>${eff}</div>
      `;
    });
    return tableBody;
  }
}
