import * as d3 from "d3";
import * as topojson from "topojson-client";
const mapatopojson = require("../json/colombia-departments.json");

// Set the dimensions for the SVG container and define margins.
const svgDimensions = { width: 1024, height: 968 };
const margin = { left: 100, right: 100, top: 200, bottom: 200 };

// Calculate the chart dimensions by subtracting margins from SVG dimensions.
const chartDimensions = {
  width: svgDimensions.width - margin.left - margin.right,
  height: svgDimensions.height - margin.bottom - margin.top,
};

// Create a Mercator projection and a geoPath using the projection.
const miProyeccion = d3.geoMercator();
const geoPath = d3.geoPath().projection(miProyeccion);

// Convert the TopoJSON data into GeoJSON features.
const mapageojson = topojson.feature(
  mapatopojson,
  mapatopojson.objects.COL_adm1
);

// Fit the projection to the size of the chart.
miProyeccion.fitSize(
  [chartDimensions.width, chartDimensions.height],
  mapageojson
);

// Graph component that creates a map using D3 and TopoJSON.
const Graph = (container) => {
  console.log("Container:", container);

  // Create an SVG element inside the specified container.
  const svg = d3.select(container).append("svg")
    .attr("width", chartDimensions.width)
    .attr("height", chartDimensions.height);

  console.log("SVG element:", svg.node());

  // Append paths for each GeoJSON feature to the SVG.
  svg
    .selectAll("path")
    .data(mapageojson.features)
    .enter()
    .append("path")
    .attr("class", "contorno")
    .attr("d", geoPath)
    .on("click", (m, d) => {

      // Set the department name in the window.name and reload the page.
      window.name = d.properties.NAME_1;
      window.location.reload(true);

    });
};

export default Graph;
