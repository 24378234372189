import pie from '../img/pie_pag.jpg';
import Img from '../img/admin.jpg';
import React, { useEffect, useRef, useState } from 'react';
import useUser from '../hooks/useUser';
import { useLocation } from "wouter";
import getUnverifyTests from '../services/Unverify_test';
import Modal from '../components/Popup';

export const AdminTests = () => {

    // Retrieve necessary hooks and information
    const [, navigate] = useLocation();
    const { isLogged } = useUser();
    const admin = window.sessionStorage.getItem("admin");

    // Effect to handle redirects based on user status
    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
            window.location.reload(true);
        }
        else if (admin === 'false' || admin === null) {
            navigate('/multiple_tests');
            window.location.reload(true);
        }

    }, [isLogged, navigate, admin])

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    // State for controlling the modal
    const [modalDeleteTest, showModalDeleteTest] = useState(false);

    // Refs for test container and pagination
    const usersContainerRef = useRef(null);
    const pagContainerRef = useRef(null);

    // Function to fetch unreviewed tests
    function unreviewedTests() {
        // Set visibility and call service to fetch data
        document.getElementById("ListTests").className = "Vis";
        document.getElementById("TestsR").className = "btn btn-outline-light me-2 btn-lg";
        getUnverifyTests(usersContainerRef.current, pagContainerRef.current);
    }

    // Function to navigate to the multiple_tests page
    function upload() {
        navigate("/multiple_tests");
        window.location.reload(true);
    }

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>
                        ADMINISTRAR TESTS
                    </h1>
                    <p>
                        Panel de administrador
                    </p>
                </div>
                <div className='App-backg2'>
                    <div>
                        <h2 className='Down'>
                            Seleccione los tests que desea administrar
                        </h2>
                        <table className='table table-borderless'>
                            <tbody className='Users'>
                                <tr>
                                    <td>
                                        <button id="TestsR" className="btn btn-light me-2 btn-lg" type="button" onClick={() => unreviewedTests()}>Tests en revisión</button>
                                    </td>
                                    <td>
                                        <button className="btn btn-light me-2 btn-lg" type="button" onClick={() => upload()}>Subir archivos</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='App-backg3'>
                    <div id="ListTests" className='Inv'>
                        <div className='Top Down'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            ID del cromosoma
                                        </th>
                                        <th scope="col">
                                            Editar
                                        </th>
                                        <th scope="col">
                                            Eliminar
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="Tests" ref={usersContainerRef} >
                                </tbody>
                            </table>
                            <div>
                                <button className="btn btn-outline-dark btn-sm" id="previousButton">«</button>
                                <small className='Top Right Left' ref={pagContainerRef} ></small>
                                <button className="btn btn-outline-dark btn-sm" id="nextButton">»</button>
                            </div>
                            <div id="btnAdminTest" className='Inv'>
                                <button className="btn btn-outline-dark btn-lg Top" onClick={()=> showModalDeleteTest(!modalDeleteTest)}>Eliminar tests</button>
                                <Modal
                                    estado={modalDeleteTest}
                                    cambiarEstado={showModalDeleteTest}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de eliminar estos test? Esta acción no se puede deshacer más adelante.</p>
                                            <button id="btnDeleteTests" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalDeleteTest(!modalDeleteTest)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={pie} className='App-pie' alt='footer' />
            </div>
        </header>


    )
}
