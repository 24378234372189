import { getGenes } from '../services/Genes.js';
import pie from '../img/pie_pag.jpg';
import React, { useState, useRef } from 'react';
import Img from '../img/medicaments.jpeg';
import useTour from '../components/Tour.tsx';
import { useTranslation } from 'react-i18next';

export const Medicaments = () => {
    const { t } = useTranslation("Medicaments");

    // Tour steps for guiding the user through the interface.
    const STEPS = [
        {
            content: t('tour.0.content'),
            placement: "right",
            styles: {
                options: {
                    width: 300,
                },
            },
            target: ".search input",
            title: t('tour.0.title'),
        },
        {
            content: t('tour.1.content'),
            placement: "right",
            target: ".search button",
            title: t('tour.1.title'),
        }
    ];

    // State to store the user's search input
    const [busqueda, setBusqueda] = useState("");

    // Function to handle the search
    const handleChange = e => {
        // Set the search input in state and store it in a global variable
        const value = e.target.value;
        if (value !== undefined) {
            window.med = value.toLowerCase()
        }
        setBusqueda(e.target.value);
        console.log("Busqueda: " + e.target.value);
        window.frecuency_text = t('frecuency_text')
        window.value_text = t('value_text')
    }

    // Refs for various containers
    const genContainerRef = useRef(null);
    const varContainerRef = useRef(null);
    const aleContainerRef = useRef(null);
    const effContainerRef = useRef(null);
    const freContainerRef = useRef(null);

    // Function to handle the search
    const gene = () => {
        // Show the gene container and fetch genes
        document.getElementById("gen").className = "Vis";
        document.getElementById("ale").className = "Inv";
        document.getElementById("var").className = "Inv";
        getGenes(genContainerRef.current);

        // Store container refs in global variables for future use
        window.varCon = varContainerRef.current;
        window.aleCon = aleContainerRef.current;
        window.effCon = effContainerRef.current;
        window.freCon = freContainerRef.current;

    }

    // Style for the background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };


    const tour = useTour(STEPS, "MED_KEY");

    return (
        <header className="App-header">
            {tour}
            <div className='App-backg1'>
                <div className="Back" style={backgroundStyle}>
                    <p />
                    <h1>{t('title')}</h1>
                    <p>{t('subtitle')}</p>
                </div>
            </div>
            <div className='App-backg2'>
                {t('search_instruction')}
                <p></p>
                <nav className="navbar bg-body-tertiary">
                    <div className="container-fluid">
                        <form className="d-flex Buscar search" role="search">
                            <input className="form-control me-3" type="search" placeholder={t('search_placeholder')} aria-label="Search" value={busqueda} onChange={handleChange} />
                            <button className="btn btn-outline-light" type="button" id="search" onClick={() => gene()}>{t('search_button')}</button>
                        </form>
                    </div>
                </nav>
            </div>
            <div className='App-backg3' id="far" >
                <table className="table table-borderless">
                    <tbody>
                        <tr className='Alelos'>
                            <td id="gen" className="Inv genes">
                                {t('gene_info')}
                                <p></p>
                                <div id="Genes" ref={genContainerRef}></div>
                            </td>
                            <td id="var" className="Inv variants">
                                {t('variants')}
                                <p></p>
                                <div id="Variants" ref={varContainerRef}></div>
                            </td>
                            <td id="ale" className="Inv">
                                {t('genotypes')}
                                <p></p>
                                <table id="Alelos" className='table table-light table-bordered'>
                                    <tbody>
                                        <tr ref={aleContainerRef}>
                                        </tr>
                                    </tbody>
                                </table>
                                <div id="eff" className='Inv'>
                                    <div id="Effects" ref={effContainerRef}></div>
                                    <p></p>
                                    <div id="fre" className='Inv' ref={freContainerRef}></div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <img src={pie} className='App-pie' alt='footer' />
            </div>
        </header>
    )

}
