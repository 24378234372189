import { navigate } from "wouter/use-location";

// API endpoint for verifying a test.
const ENDPOINT = process.env.REACT_APP_API_URL + 'api/verify-test/';

/**
 * Verifies a test with the specified test ID.
 * @param {string} test_id - The ID of the test to be verified.
 */
export default async function verifyTest(test_id) {
  try {
    // Retrieve the authentication token from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');

    // Send a POST request to the API endpoint to verify the test.
    const response = await fetch(ENDPOINT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token
      },
      body: JSON.stringify({
        id_chromosome: test_id
      }),
    });

    // Check if the response is successful.
    if (!response.ok) {
      throw new Error('Response is NOT ok');
    }

    // Parse the response data as JSON.
    const data = await response.json();

    // Display a message based on the response data.
    if (data.message === `El test con id ${test_id} ha sido verificado`) {
      window.alert(`El test con id ${test_id} ha sido modificado y subido a la base de datos`);
    }

    // Remove session storage items related to the test.
    window.sessionStorage.removeItem('aleRef');
    window.sessionStorage.removeItem('aleAlt');
    window.sessionStorage.removeItem('pharmGKB');

    // Navigate to the "/admin/tests" route.
    navigate("/admin/tests");

    // Reload the page to reflect changes.
    window.location.reload(true);

  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}
