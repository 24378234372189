import useUser from '../hooks/useUser';
import { useLocation } from "wouter";
import Img from '../img/files.jpg';
import pie from '../img/pie_pag.jpg';
import { useEffect, useState } from "react";
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import editTests from '../services/Edit_test';
import Modal from '../components/Popup';

// Function to validate form fields
const validateFields = values => {
    const errors = {};

    // Validation for each field
    if (values.pharmGKB.length > 15) {
        errors.pharmGKB = "El ID no debe superar los 15 caracteres, debe subir el código que aparece despues de variant/";
    }

    if (values.gen.length > 30) {
        errors.gen = "El gen no debe superar los 30 caracteres";
    }

    if (values.rsID.length > 20) {
        errors.rsID = "El rsID no debe superar los 20 caracteres";
    }

    return errors;
}

// Initial values for the form fields
const initialValues = {
    pharmGKB: "",
    gen: "",
    rsID: ""
}

// Main component for editing a test
export function EditTest() {
    const [, navigate] = useLocation();

    // Retrieve test information from sessionStorage
    const test = window.sessionStorage.getItem('test');

    // Declare variables for test information
    var test_id;
    var info;

    // Parse test information if available
    if (test != null) {
        info = test.split("-");
        test_id = info[1]
    }

    // State for controlling the modal
    const [modalTest, showModalTest] = useState(false);

    // Check user login status and admin status on component mount
    const { isLogged } = useUser();
    const admin = window.sessionStorage.getItem("admin");

    // Function to handle the edit action
    function edit() {
        const gen = window.sessionStorage.getItem('gen');
        const rsID = window.sessionStorage.getItem('rsID');
        const pharmGKB = window.sessionStorage.getItem('pharmGKB');
        editTests(test_id, gen, rsID, pharmGKB);
        showModalTest(!modalTest);
    }

    // Effect to handle navigation and reloading based on user status
    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
            window.location.reload(true);
        }
        else if (admin === 'false' || admin === null) {
            navigate('/multiple_tests');
            window.location.reload(true);
        }
        if (test == null) {
            navigate('/admin/tests');
            window.location.reload(true);
        }

    }, [isLogged, navigate, test, admin])

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>
                        EDITAR TEST
                    </h1>
                    <p>
                        Plataforma de Vigilancia Farmacogenómica
                    </p>
                </div>
            </div>
            <div className='App-backg5'>
                <Formik
                    initialValues={initialValues}
                    validate={validateFields}
                    onSubmit={(values, { setFieldError }) => {
                        window.sessionStorage.setItem('rsID', values.rsID);
                        window.sessionStorage.setItem('gen', values.gen);
                        window.sessionStorage.setItem('pharmGKB', values.pharmGKB);
                        showModalTest(!modalTest);
                    }}
                >
                    {({ errors }) => (
                        <Form>
                            <div className="form">
                                <div className="col-md-12">
                                    <Field
                                        className='form-control'
                                        name="ID cromosoma"
                                        placeholder={test_id}
                                        disabled
                                    />
                                </div>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="col-md-6">
                                                <Field
                                                    className={errors.rsID ? 'error' : 'form-control'}
                                                    name="rsID"
                                                    placeholder="rsID"
                                                    
                                                />
                                                <ErrorMessage className='form-error' name='rsID' component='small' />
                                            </td>
                                            <td className="col-md-6">
                                                <Field
                                                    className={errors.gen ? 'error' : 'form-control'}
                                                    name="gen"
                                                    placeholder="Gen"
                                                    
                                                />
                                                <ErrorMessage className='form-error' name='gen' component='small' />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="col-md-12">
                                    <Field
                                        className={errors.pharmGKB ? 'error' : 'form-control'}
                                        name="pharmGKB"
                                        placeholder="ID de pharmGKB"
                                        
                                    />
                                    <ErrorMessage className='form-error' name='pharmGKB' component='small' />
                                </div>
                            </div>
                            <div className="form">
                                <button id='btnEdit' className='btn' type='submit'>Subir test</button>
                                <Modal
                                    estado={modalTest}
                                    cambiarEstado={showModalTest}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de modificar este test? Esta acción no se puede deshacer más adelante.</p>
                                            <button className='btn btn-outline-dark' onClick={() => edit()}>Si</button>
                                            <button className='btn btn-dark' onClick={() => showModalTest(!modalTest)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <img src={pie} className='App-pie' alt='footer' />
        </header>

    );
}