import pie from '../img/pie_pag.jpg';
import Img from '../img/admin.jpg';
import React, { useEffect, useRef, useState } from 'react';
import useUser from '../hooks/useUser';
import { useLocation } from "wouter";
import getInactiveUsers from '../services/Inactive_users';
import getActiveUsers from '../services/Active_users';
import getAdminUsers from '../services/Admin_users';
import Modal from '../components/Popup';

export const AdminUsers = () => {

    // State for controlling the modal
    const [modalRemoveAdmin, showModalRemoveAdmin] = useState(false);
    const [modalDeleteAdmin, showModalDeleteAdmin] = useState(false);
    const [modalGrantAdmin, showModalGrantAdmin] = useState(false);
    const [modalDeleteUser, showModalDeleteUser] = useState(false);
    const [modalActiveUser, showModalActiveUser] = useState(false);

    // Retrieve necessary hooks and information
    const [, navigate] = useLocation();
    const { isLogged } = useUser();
    const admin = window.sessionStorage.getItem("admin");

    // Effect to handle redirects based on user status
    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
            window.location.reload(true);
        }
        else if (admin === 'false' || admin === null) {
            navigate('/multiple_tests');
            window.location.reload(true);
        }

    }, [isLogged, navigate, admin])

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    // Refs for user container and pagination
    const usersContainerRef = useRef(null);
    const pagContainerRef = useRef(null);

    // Function to fetch inactive users
    function inactiveUsers() {
        // Set visibility and call service to fetch data
        document.getElementById("ListUsers").className = "Vis";
        document.getElementById("btnU2").className = "Inv";
        document.getElementById("btnU3").className = "Inv";
        document.getElementById("UsersR").className = "btn btn-outline-light me-2 btn-lg";
        document.getElementById("UsersC").className = "btn btn-light me-2 btn-lg";
        document.getElementById("UsersA").className = "btn btn-light me-2 btn-lg";
        getInactiveUsers(usersContainerRef.current, pagContainerRef.current);
    }

    // Function to fetch active users
    function activeUsers() {
        // Set visibility and call service to fetch data
        document.getElementById("ListUsers").className = "Vis";
        document.getElementById("btnU").className = "Inv";
        document.getElementById("btnU3").className = "Inv";
        document.getElementById("UsersR").className = "btn btn-light me-2 btn-lg";
        document.getElementById("UsersC").className = "btn btn-outline-light me-2 btn-lg";
        document.getElementById("UsersA").className = "btn btn-light me-2 btn-lg";
        getActiveUsers(usersContainerRef.current, pagContainerRef.current);
    }

    // Function to fetch admin users
    function adminUsers() {
        // Set visibility and call service to fetch data
        document.getElementById("ListUsers").className = "Vis";
        document.getElementById("btnU").className = "Inv";
        document.getElementById("btnU2").className = "Inv";
        document.getElementById("UsersR").className = "btn btn-light me-2 btn-lg";
        document.getElementById("UsersC").className = "btn btn-light me-2 btn-lg";
        document.getElementById("UsersA").className = "btn btn-outline-light me-2 btn-lg";
        getAdminUsers(usersContainerRef.current, pagContainerRef.current);
    }

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>
                        ADMINISTRAR USUARIOS
                    </h1>
                    <p>
                        Panel de administrador
                    </p>
                </div>
                <div className='App-backg2'>
                    <div>
                        <h2 className='Down'>
                            Seleccione los usuarios que desea administrar
                        </h2>
                        <table className='table table-borderless'>
                            <tbody className='Users'>
                                <tr>
                                    <td>
                                        <button id="UsersR" className="btn btn-light me-2 btn-lg" type="button" onClick={() => inactiveUsers()}>Usuarios en revisión</button>
                                    </td>
                                    <td>
                                        <button id="UsersC" className="btn btn-light me-2 btn-lg" type="button" onClick={() => activeUsers()}>Usuarios certificados</button>
                                    </td>
                                    <td>
                                        <button id="UsersA" className="btn btn-light me-2 btn-lg" type="button" onClick={() => adminUsers()}>Administradores</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='App-backg3'>
                    <div id="ListUsers" className='Inv'>
                        <div className='Top Down'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Nombre
                                        </th>
                                        <th scope="col">
                                            Apellido
                                        </th>
                                        <th scope="col">
                                            Correo
                                        </th>
                                        <th scope="col">
                                            Profesión
                                        </th>
                                        <th scope="col">
                                            Empresa
                                        </th>
                                        <th scope="col">
                                            Seleccionar usuario
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="Users" ref={usersContainerRef} >
                                </tbody>
                            </table>
                            <div>
                                <button className="btn btn-outline-dark btn-sm" id="previousButton">«</button>
                                <small className='Top Right Left' ref={pagContainerRef} ></small>
                                <button className="btn btn-outline-dark btn-sm" id="nextButton">»</button>
                            </div>
                            <div id="btnU" className='Inv'>
                                <button className="btn btn-outline-dark btn-lg Right"  onClick={()=> showModalActiveUser(!modalActiveUser)}>Activar usuarios</button>
                                <Modal
                                    estado={modalActiveUser}
                                    cambiarEstado={showModalActiveUser}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de activar estos usuarios? Esta acción les permitirá subir tests.</p>
                                            <button id="btnGrantInactiveUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalActiveUser(!modalActiveUser)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                                <button className="btn btn-outline-dark btn-lg" onClick={()=> showModalDeleteUser(!modalDeleteUser)}>Eliminar usuarios</button>
                                <Modal
                                    estado={modalDeleteUser}
                                    cambiarEstado={showModalDeleteUser}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de eliminar estos usuarios? Esta acción no se puede deshacer más adelante.</p>
                                            <button id="btnDeleteInactiveUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalDeleteUser(!modalDeleteUser)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                            </div>
                            <div id='btnU2' className='Inv'>
                                <button className="btn btn-outline-dark btn-lg Right" onClick={() => showModalGrantAdmin(!modalGrantAdmin)} >Volver administrador</button>
                                <Modal
                                    estado={modalGrantAdmin}
                                    cambiarEstado={showModalGrantAdmin}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de otorgar los permisos de administrador a estos usuarios?</p>
                                            <button id="btnGrantActiveUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalGrantAdmin(!modalGrantAdmin)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                                <button className="btn btn-outline-dark btn-lg"  onClick={()=> showModalDeleteUser(!modalDeleteUser)}>Eliminar usuarios</button>
                                <Modal
                                    estado={modalDeleteUser}
                                    cambiarEstado={showModalDeleteUser}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de eliminar estos usuarios? Esta acción no se puede deshacer más adelante.</p>
                                            <button id="btnDeleteActiveUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalDeleteUser(!modalDeleteUser)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                            </div>
                            <div id='btnU3' className='Inv'>
                                <button className="btn btn-outline-dark btn-lg Right " onClick={()=> showModalRemoveAdmin(!modalRemoveAdmin)} >Remover permisos de administrador</button>
                                <Modal
                                    estado={modalRemoveAdmin}
                                    cambiarEstado={showModalRemoveAdmin}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de remover los permisos de administrador de estos usuarios?</p>
                                            <button id="btnRemoveAdminUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalRemoveAdmin(!modalRemoveAdmin)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                                <button className="btn btn-outline-dark btn-lg" onClick={()=> showModalDeleteAdmin(!modalDeleteAdmin)} >Eliminar usuarios administradores</button>
                                <Modal
                                    estado={modalDeleteAdmin}
                                    cambiarEstado={showModalDeleteAdmin}
                                    mostrarHeader={true}
                                    mostrarOverlay={true}
                                    posicionModal={'center'}
                                    padding={'20px'}
                                >
                                    <>
                                        <div className='popUp'>
                                            <h1>¡Espera!</h1>
                                            <p>¿Estás seguro de eliminar estos administradores? Esta acción no se puede deshacer más adelante.</p>
                                            <button id="btnDeleteAdminUsers" className='btn btn-outline-dark'>Si</button>
                                            <button className='btn btn-outline-dark' onClick={() => showModalDeleteAdmin(!modalDeleteAdmin)}>No</button>
                                        </div>
                                    </>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={pie} className='App-pie' alt='footer' />
            </div>
        </header>


    )
}
