import { useCallback, useContext, useState } from 'react'
import Context from '../context/UserContext'
import loginService from '../services/Login'
import signupService from '../services/Signup'
import logoutService from '../services/Logout'
import isAdminService from '../services/Is_admin'

export default function useUser() {
  // Access the global context for JWT token
  const { jwt, setJWT } = useContext(Context);

  // State to manage loading, errors, and signup status
  const [state, setState] = useState({ loading: false, error: false, signup: false })

  // State to manage user admin status
  const [userAdmin, setUserAdmin] = useState({ getAdmin: false })


  // Function to check if a user is an admin
  const isAdmin = useCallback(async ({ email }) => {
    setUserAdmin({ getAdmin: true })

    // Call the isAdmin service
    await isAdminService({ email })
      .then(rta => {

        // Save if the user is admin to sessionStorage
        window.sessionStorage.setItem('admin', rta)
        setUserAdmin({ getAdmin: rta })
      }).catch(err => {

        // Handle isAdmin error
        window.sessionStorage.removeItem('admin')
        setUserAdmin({ getAdmin: false })
        setState({ loading: false, error: true, signup: false })
        console.error(err)
      })
  }, [setUserAdmin]);

  // Function to handle user login
  const login = useCallback(async (email, password, csrfToken) => {
    setState({ loading: true, error: false, signup: false })
    await loginService(email, password, csrfToken)
      .then(async jwt => {

        // Save JWT token to sessionStorage
        window.sessionStorage.setItem('jwt', jwt)

        // Check if the user is an admin
        await isAdmin({ email })

        // Save user email to sessionStorage
        window.sessionStorage.setItem('email', email)

        // Update state
        setState({ loading: false, error: false, signup: false })

        // Set the JWT token in the context
        setJWT(jwt)
      })
      .catch(err => {
        // Handle login error
        window.sessionStorage.removeItem('jwt')
        window.sessionStorage.removeItem('admin')
        window.sessionStorage.removeItem('email')
        setUserAdmin({ getAdmin: false })
        setState({ loading: false, error: true, signup: false })
        console.error(err)
      })

  }, [setJWT, isAdmin, setUserAdmin])

  // Function to handle user signup
  const signup = useCallback(async ({ email, name, lastName, password, company, job }) => {
    setState({ loading: true, error: false, signup: false })
    // Call the signup service
    await signupService({ email, name, lastName, password, company, job })

    // Check the signup response in sessionStorage
    const response = window.sessionStorage.getItem("signup");

    // Update state based on the signup response
    if (response === "error") {
      setState({ loading: false, error: false, signup: false })
    } else {
      setState({ loading: false, error: false, signup: true })
    }
  }, [])

  // Function to handle user logout
  const logout = useCallback(async () => {
    setState({ loading: false, error: false, signup: false })

    // Retrieve the JWT token from sessionStorage
    const token = window.sessionStorage.getItem('jwt')

    // Call the logout service
    await logoutService({ token })

    // Remove stored session data
    window.sessionStorage.removeItem('jwt')
    window.sessionStorage.removeItem('admin')
    window.sessionStorage.removeItem('email')
    window.sessionStorage.removeItem('test')

    // Clear JWT token in the context
    setJWT(null)

    // Clear user admin status
    setUserAdmin({ getAdmin: false })
  }, [setJWT, setUserAdmin])

  // Return the user-related information and functions
  return {
    isLogged: Boolean(jwt),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    isSignup: state.signup,
    admin: userAdmin.getAdmin,
    login,
    logout,
    signup,
    isAdmin
  }
} 