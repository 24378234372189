/**
 * Asynchronously retrieves a graph image by rsID and department.
 * 
 * @param {HTMLElement} tableBody - The HTML element where the graph image will be appended.
 * @param {string} department - The department identifier used to filter the graph.
 * @param {string} rsid - The rsID used to identify the specific graph.
 */
export async function getGraphByrsID(tableBody, department, rsid) {

  // Construct the URL with query parameters for department and rsID
  const url = process.env.REACT_APP_API_URL + 'api/get-graph-rsid?department=' + department + "&&rsID=" + rsid + "&&language=" + window.navigator.language;

  // Define headers for the fetch request
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    // Perform a GET request to the specified URL
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful
    if (response.ok) {

      // Convert the response to a blob (binary data)
      const blob = await response.blob();

      // Create a URL for the blob data
      const imageUrl = URL.createObjectURL(blob);

      // Update the tableBody's class and append the image element to it
      tableBody.className = "Top"
      tableBody.innerHTML = `<img src=${imageUrl} alt="Graph" />`;

    } else {
      // Log an error message if the response is not successful
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    // Catch and log any errors during the fetch operation
    console.error('Error in the request:', error);
  }
}
