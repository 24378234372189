import { Link } from 'react-router-dom';
import pie from '../img/pie_pag.jpg';
import React, { useEffect } from 'react';
import Img from '../img/admin.jpg';
import { useLocation } from "wouter";
import useUser from '../hooks/useUser';

export const Admin = () => {

    // Retrieve admin status and navigate function from hooks
    const admin = window.sessionStorage.getItem("admin");
    const [, navigate] = useLocation();
    const { isLogged } = useUser();

    // Effect to handle redirects based on user status
    useEffect(() => {
        if (!isLogged) {
            navigate('/login');
            window.location.reload(true);
        }
        else if (admin === 'false' || admin === null) {
            navigate('/multiple_tests');
            window.location.reload(true);
        }

    }, [isLogged, navigate, admin])

    // Style for background image
    const backgroundStyle = {
        background: `url(${Img}) no-repeat center center fixed`,
        backgroundSize: 'cover',
    };

    return (
        <header className="App-header">
            <div className='App-backg1'>
                <div style={backgroundStyle} className='Back'>
                    <p />
                    <h1>
                        BIENVENIDO ADMIN
                    </h1>
                    <p>
                        Panel de administrador
                    </p>
                </div>
                <div className='App-backg2'>
                    <div className='Layout'>
                        <p>
                            Esta es la herramienta central para la gestión de usuarios y pruebas en nuestra plataforma. Como administrador, tienes acceso a una amplia gama de funciones para garantizar un control eficiente y efectivo. Tus principales responsabilidades incluyen:
                        </p>
                        <p>
                            * Verificar Usuarios: En esta sección, puedes revisar y aprobar nuevas solicitudes de usuarios. Garantiza la integridad y autenticidad de la comunidad al verificar y autorizar cuentas.
                        </p>
                        <p>
                            * Verificar y Editar Tests: Accede a la información detallada de las pruebas disponibles en nuestra plataforma. Verifica la precisión y calidad de los contenidos, y realiza ediciones si es necesario para mantener la integridad de las evaluaciones.
                        </p>
                    </div>
                    <div className='Down'>
                        <table>
                            <tbody>
                                <tr className='form'>
                                    <td>
                                        <Link className="btn me-2 btn-lg Right" type="button" to="/admin/users" >Administrar usuarios</Link>
                                    </td>
                                    <td>
                                        <Link className="btn me-2 btn-lg Left" type="button" to="/admin/tests">Administrar tests</Link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <img src={pie} className='App-pie' alt='footer' />
            </div>
        </header>


    )
}
