// Define the API endpoint for uploading VCF test files.
const ENDPOINT = process.env.REACT_APP_API_URL + 'api/upload-tests-vcf/';

/**
 * Upload a VCF test file to the server.
 * @param {Object} file - The VCF file to be uploaded.
 */
export default async function upload_tests({ file, t }) {
  try {
    // Retrieve the JWT token and email from sessionStorage.
    const token = window.sessionStorage.getItem('jwt');
    const email = window.sessionStorage.getItem("email");

    const department = window.sessionStorage.getItem('department');
    const ethnicity = window.sessionStorage.getItem('ethnicity');

    // Create a FileReader to read the contents of the file.
    const reader = new FileReader();

    // Define the event handler for when file reading is completed.
    reader.onload = function (e) {
      // Extract the content of the uploaded file.
      const fileContent = e.target.result;

      // Define a boundary for the multipart form data.
      const boundary = 'boundary_example';

      // Set headers for the request.
      const headers = {
        "Content-Type": `multipart/form-data; boundary=${boundary}`,
        "Authorization": "Token " + token
      };

      // Construct the request body using multipart form data.
      const body = `--${boundary}\r\n` +
        `Content-Disposition: form-data; name="file"; filename="test.vcf"\r\n` +
        `Content-Type: text/vcard\r\n\r\n` +
        `${fileContent}\r\n` +
        `--${boundary}\r\n` +
        `Content-Disposition: form-data; name="department"\r\n\r\n` +
        `${department}\r\n` +
        `--${boundary}\r\n` +
        `Content-Disposition: form-data; name="ethnicity"\r\n\r\n` +
        `${ethnicity}\r\n` +
        `--${boundary}--\r\n` +
        `Content-Disposition: form-data; name="username"\r\n\r\n` +
        `${email}\r\n` +
        `--${boundary}--\r\n`+
        `Content-Disposition: form-data; name="language"\r\n\r\n` +
        `${window.navigator.language}\r\n` +
        `--${boundary}--\r\n`;

      // Send a POST request to upload the file to the specified endpoint.
      fetch(ENDPOINT, {
        method: 'POST',
        headers: headers,
        body: body
      })
        .then(async (response) => {

          // Parse the response data as JSON and handle success.
          const data = await response.json();

          if (!response.ok) {
            window.alert(data.error);
          }
          else {

            // Display a success message and reload the page.
            window.alert(t('upload_success'));
            window.location.reload(true);
          }
          return data.success;
        });
    };

    // Read the contents of the selected file.
    reader.readAsText(file);
  } catch (error) {
    // Handle and log any errors that occur during the process.
    console.error(error);
    throw error;
  }
}
