
// Variable to track the current page.
let currentPage = 1;

// Number of items to display per page.
const itemsPerPage = 2;

// Reference to the HTML container where the table will be displayed.
var container;

// Reference to the HTML container where the pagination will be displayed.
var pagContainer;

// Variable to store the fetched data.
var data;

// Variable to store the number of total pages.
var totalPages;
/**
 * Fetch genes related to a specific department and display them in a table.
 * @param {HTMLElement} tableBody - The HTML element where the genes will be displayed.
 */

export async function getGen(tableBody, pagBody, no_data) {
  

  // Set the container reference to the provided table body.
  container = tableBody;


  // Set the container reference to the provided page body.
  pagContainer = pagBody;

  // Get the department name from the window object.
  const name = window.name;

  // Define the API endpoint for fetching genes.
  const url = process.env.REACT_APP_API_URL + 'api/departments-search?department=' + name + "&&language=" + window.navigator.language;

  // Define headers for the API request.
  const headers = {
    'Content-Type': 'application/json',
  };

  try {
    
    // Send a GET request to the API endpoint to fetch genes.
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    });

    // Check if the response is successful.
    if (response.ok) {
      // Parse the response data as JSON.
      data = await response.json();
      if(data == ""){
        window.alert(no_data);
        document.getElementById("pagBtn").className = "Inv";
        document.getElementById("dataBtn").className = "Vis Top";
      }

      // Display the genes in the specified table body with pagination.
      displayDataWithPagination();
    } else {
      console.error('Error in the request:', response.statusText);
    }
  } catch (error) {
    console.error('Error in the request:', error);
  }
}

const displayDataWithPagination = () => {
  totalPages = Math.ceil(data.length / itemsPerPage);
  if (container != null) {

    if (pagContainer != null){
      // Display pagination information.
      pagContainer.className = "Top Right Left";
      pagContainer.innerHTML = (totalPages === 0) ?
        `<small>${window.page}${currentPage}${window.of_string}1</small>` :
        `<small>${window.page}${currentPage}${window.of_string}${totalPages}</small>`;
    }
    // Clear the existing content of the table body.
    container.innerHTML = ""

    // Calculate the start and end index for the current page.
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Slice the data array to get the items for the current page.
    const paginatedData = data.slice(startIndex, endIndex);

    // Loop through the paginated data to display genes.
    paginatedData.forEach(dep => {
      const link = dep.pharmgkb.split("/");
      for (let i = 0; i < dep.genotypes.length; i++) {
        const num = Math.ceil(dep.pharmasnp.length / 10)
        var str = "";
        var k = 0;
        for (let j = 0; j < num; j++){
          str += dep.pharmasnp.substring(k,(j+1)*10)+ "\n"
          
          k += (j+1)*10
        }
        const num2 = Math.ceil(dep.genotypes[i].length / 10)
        var str2 = "";
        var k2 = 0;
        for (let j2 = 0; j2 < num2; j2++){
          str2 += dep.genotypes[i].substring(k2,(j2+1)*10)+ "\n"
          
          k2 += (j2+1)*10
        }
          // Display the gene information in the specified format.
          container.innerHTML += `
            <tr>
              <td>${dep.gen}</td>
              <td>${str}</td>
              <td><a href="https://www.pharmgkb.org/variant/${link[4]}">${link[4]}</a></td>
              <td>${str2}</td>
              <td>${dep.Frequencies[i]}</td>
              <td>${dep.ethnicity}</td>
            </tr>
          `;
      }
    });

    return container;
  }
}

// Function to navigate to the previous page.
function goToPreviousPage() {
  if (currentPage > 1) {
    currentPage--;
    updateTable();
  }
}

// Function to navigate to the next page.
function goToNextPage() {
  if (currentPage < totalPages) {
    currentPage++;
    updateTable();
  }
}

// Function to update the table with data from the current page.
function updateTable() {
  container.innerHTML = ''; // Clear the table before adding new data.
  displayDataWithPagination();
}

// Event listener for the "Previous" button click event.
document.addEventListener("click", function (event) {
  // Get the HTML element ID that triggered the click event.
  const id = event.target.id;
  if (id.includes("previousButton")) {
    // Call the function to navigate to the previous page.
    goToPreviousPage();
  }
}, false);

// Event listener for the "Next" button click event.
document.addEventListener("click", function (event) {
  // Get the HTML element ID that triggered the click event.
  const id = event.target.id;
  if (id.includes("nextButton")) {
    // Call the function to navigate to the next page.
    goToNextPage();
  }
}, false);
